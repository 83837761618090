import { Component } from "@angular/core";


@Component({
    selector: 'app-msgresetmail',
    template: `
    
            <div class="col-md-5 col-md-offset-2">
             <h2 class="text-success bg-dark">Sua senha foi resetada!</h2>
             <h3>Um email foi enviado para sua caixa de mensagens com sua nova senha.
            </h3>
             </div>
    `
})
export class MsgresetmailComponent {
 
}