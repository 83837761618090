import { EventEmitter } from '@angular/core';

import { Error } from './error.model';

export class ErrorService {
    errorOccurred = new EventEmitter<Error>(true);

    handleError(error: any) {
        if(error.error){
        const errorData = new Error(error.title, error.error.message);
        this.errorOccurred.emit(errorData);
        }
    }
}
